export default (client) => ({
  getInventory(contractId, page = 1, ordering = "tag_id", limit = 20) {
    return new Promise((resolve, reject) => {
      client
        .get(
          `inventory/?contract_id=${contractId}&limit=${limit}&ordering=${ordering}&page=${page}`
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },
});
