export default (client) => ({
  changePass(input) {
    return new Promise((resolve, reject) => {
      client.post("auth/password/change/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  getPaymentMethods(id) {
    return new Promise((resolve, reject) => {
      client.post(`payment-methods/${id}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
});
