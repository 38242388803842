import axios from "axios";

import Auth from "./auth";
import Contracts from "./contracts";
import Inventory from "./inventory";
import Orders from "./orders";
import User from "./user";
import Public from "./public";
import Payments from "./payments";
import Funnel from "./funnel";

import {
  requestInterceptor,
  authRequestInterceptor,
  responseInterceptor,
  responseErrorInterceptor,
} from "./interceptors";

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const clientNoAuth = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const clientAPIGateway = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Set request interceptors
client.interceptors.request.use(authRequestInterceptor);
clientNoAuth.interceptors.request.use(requestInterceptor);
clientAPIGateway.interceptors.request.use(requestInterceptor);

// Set response interceptors
client.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export default {
  // setAuthorization,
  auth: Auth(client),
  contracts: Contracts(client),
  inventory: Inventory(client),
  orders: Orders(client),
  user: User(client),
  public: Public(clientNoAuth),
  payments: Payments(clientAPIGateway),
  funnel: Funnel(clientAPIGateway),
};
