export default (client) => ({
  getContracts() {
    return new Promise((resolve, reject) => {
      client.get("contracts/").then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  getContractById(id) {
    return new Promise((resolve, reject) => {
      client.get(`contracts/${id}/`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  postDeliveryOrder(id, payload) {
    return new Promise((resolve, reject) => {
      client.post(`contracts/${id}/delivery-order/`, payload).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  postPickupOrder(id, payload) {
    return new Promise((resolve, reject) => {
      client.post(`contracts/${id}/pickup-order/`, payload).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  getInvoicesList(subscriptionId) {
    return new Promise((resolve, reject) => {
      client.get(`invoices/?subscription_id=${subscriptionId}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
});
