import { client } from "./index";
// import { getApiVersion, saveApiVersion } from "@/services/version.js";
import store from "@/store";
import Vue from "vue";

/**
 * Helper methods
 */
const MAX_429_RETRIES = 4;
const exponentialBackoffDelay = (retryAttempt = 0) => {
  const delay = Math.pow(2, retryAttempt) * 600;
  const randomSum = delay * 0.4 * Math.random(); // 0-40% of the delay
  return delay + randomSum;
};

/**
 * Helper method to check if current API version has changed in order to reload chunks from CDN
 * @param {axios.headers} headers
 * @returns true/false
 */
// const setAndGetApiVersion = (headers) => {
//   const apiVersion = headers["x-api-version"];
//   let refreshVersion = false;

//   // If not set => simply set it
//   if (apiVersion && !getApiVersion()) {
//     saveApiVersion(apiVersion);
//   }

//   // If it is already set => propagate this change and reload window
//   if (apiVersion && apiVersion != getApiVersion()) {
//     saveApiVersion(apiVersion);
//     refreshVersion = true;
//   }

//   return refreshVersion;
// };

/**
 * Response interceptor to reload window and retry the initial request on version change
 */
export const responseInterceptor = (res) => {
  // if (setAndGetApiVersion(res.headers)) {
  //   // Retry it
  //   window.location.reload();
  //   client.request(res.config);
  // }

  return res.data;
};

export const responseErrorInterceptor = async (error) => {
  const originalRequest = error.config;
  const response = error.response;
  let delay = 0;

  // Add retryCount to config if not present
  originalRequest._tooManyRetryCount = originalRequest._tooManyRetryCount || 0;

  // Logout user if we get 401 status error and original request is auth/refresh
  if (
    response &&
    response.status === 401 &&
    originalRequest.url === "/auth/refresh/"
  ) {
    store.dispatch("auth/logout");
  }

  if (
    response &&
    (!originalRequest._retry401 || !originalRequest._retry429) &&
    [401, 429].includes(response.status)
  ) {
    // If we are facing a 401 error, refresh token
    if (response.status === 401) {
      originalRequest._retry401 = true;
      const refreshToken = Vue.prototype.$getToken("refresh-token");
      try {
        const response = await store.dispatch("auth/refreshToken", {
          refresh: refreshToken,
        });
        originalRequest.headers.Authorization = `Bearer ${response.access}`;
      } catch (error) {
        store.dispatch("auth/logout");
      }
    }

    if (response.status === 429) {
      originalRequest._tooManyRetryCount += 1;
      originalRequest._retry401 = false;
      originalRequest._retry429 =
        originalRequest._tooManyRetryCount >= MAX_429_RETRIES;

      // Set the delay time before retry the request
      delay = exponentialBackoffDelay(originalRequest._tooManyRetryCount);
    }

    // Finally, run the request
    return new Promise((resolve) =>
      setTimeout(() => resolve(client(originalRequest)), delay)
    );
  }

  // In another case, reject
  return Promise.reject(error);
};

export const requestInterceptor = async (config, isAuth = false) => {
  // Load site language from store
  const siteLanguage =
    store.state.languages.siteLanguage ||
    (await store.dispatch("languages/getSiteLanguage"));

  if (isAuth) {
    const accessToken = Vue.prototype.$getToken("access-token");
    config.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  // Set Accept-Language header
  config.headers["Accept-Language"] = siteLanguage;
  return config;
};

export const authRequestInterceptor = async (config) => {
  return await requestInterceptor(config, true);
};
