export default (client) => ({
  getUser(id) {
    return new Promise((resolve, reject) => {
      client.get(`/users/${id}/`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  updateUser(payload) {
    return new Promise((resolve, reject) => {
      client.patch(`/users/${payload.id}/`, payload).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  changePassword(input) {
    return new Promise((resolve, reject) => {
      client.post("auth/password/change/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  refreshAccessToken(input) {
    return new Promise((resolve, reject) => {
      client.post("auth/refresh/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
});
