export default (client) => ({
  pickupReserve(datas) {
    return new Promise((resolve, reject) => {
      client
        .post(
          `hubspot-funnel?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          datas
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },
});
