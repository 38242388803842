export default (client) => ({
  paymentIntent(payload) {
    return new Promise((resolve, reject) => {
      client
        .post(
          `chargebee-payment-intent?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          payload
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },

  pay(payload) {
    return new Promise((resolve, reject) => {
      client
        .post(
          `chargebee-pay?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          payload
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },

  releaseTransaction(payload) {
    return new Promise((resolve, reject) => {
      client
        .patch(
          `chargebee-release-transaction?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          payload
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },

  getPaymentLinkDetails(id) {
    return new Promise((resolve, reject) => {
      client
        .get(
          `chargebee-payment-link?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          {
            params: {
              id,
            },
          }
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },

  customer(payload) {
    return new Promise((resolve, reject) => {
      client
        .post(
          `chargebee-customer?key=${process.env.VUE_APP_API_GATEWAY_BACKEND_API_KEY}`,
          payload
        )
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },
});
