export default (client) => ({
  getOrdersList(contractId, page = 1, limit = 20) {
    return new Promise((resolve, reject) => {
      client
        .get(`orders/?contract_id=${contractId}&limit=${limit}&page=${page}`)
        .then(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            throw new Error(err);
          }
        );
    });
  },
  getOrderById(contractId, orderId) {
    return new Promise((resolve, reject) => {
      client.get(`orders/${orderId}/?contract_id=${contractId}`).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
});
