export default (client) => ({
  login(input) {
    return new Promise((resolve, reject) => {
      client.post("auth/login/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  getCountriesList() {
    return new Promise((resolve, reject) => {
      client.get("countries/").then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  recoverPassword(email) {
    return new Promise((resolve, reject) => {
      client.post("auth/password/reset/", email).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  recoverPasswordConfirm(input) {
    return new Promise((resolve, reject) => {
      client.post("auth/password/reset/confirm/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
  activateAccount(input) {
    return new Promise((resolve, reject) => {
      client.post("activate-account/", input).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
          throw new Error(err);
        }
      );
    });
  },
});
